import PersonnelGrid from 'components/PersonnelGrid';
import { PersonnelGridRowData } from 'components/PersonnelGrid/PersonnelGrid';
import PersonnelAvailabilityGrid from 'components/PersonnelUnassignedGrid';
import GanttChart, { TASKBAR_HEIGHT } from 'components/shared/Gantt/GanttChart';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import TabPanel from 'components/shared/TabPanel';
import { ROLES, ROLES_BY_NAME, ROLES_FULL_DISPLAY_NAME_BY_JOB_TYPE } from 'constants/roles';
import { PERSONNEL_GANTT_ID, PERSONNEL_GRID_ID, PERSONNEL_UNASSIGNED_GRID_ID } from 'constants/syncfusionComponentIds';
import useRoles from 'hooks/useRoles';
import { DateTime } from 'luxon';
import { FC, Fragment, useRef, useState, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedDistrictsState, selectedEmployeeTypeState } from 'recoil/selectedDistrict/atom';
import { SortOrderOption, usePersonListResultForGanttQuery } from 'types/generated/graphql';
import { convertUTCDateToLocalDate, graphqlDateToSyncfusionDate, lengthOfTimeInYearsSortComparer } from 'utils/general';
import { OpenInNew } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Popper,
  Stack,
  SxProps,
  Tab,
  Tabs,
  TextField,
  Theme,
  Typography,
  autocompleteClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ColumnDirective, GanttModel } from '@syncfusion/ej2-react-gantt';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useGlobalSearchParams } from 'components/SearchParamsProvider/SearchParamsProvider';
import StyledDatePicker from 'components/shared/KeyboardDatePicker';
import useCurrentUser from 'hooks/useCurrentUser';
import useDataFilter from 'hooks/useDataFilter';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { hpYellowPrimary } from 'constants/themes/colors';

const indicatorIconStyle: SxProps<Theme> = (theme: Theme) => ({
  color: `${theme.palette.primary.contrastText} !important`,
  marginRight: theme.spacing(1),
});

const cellIconButtonStyle: SxProps<Theme> = (theme: Theme) => ({
  padding: 0,
  color: theme.palette.primary.main,
});

const cellIconStyle: SxProps<Theme> = {
  height: '18px',
  verticalAlign: 'middle',
};

const roleSelectStyle: SxProps<Theme> = () => ({
  width: '250px',
});

const dateSelectStyle: SxProps<Theme> = () => ({
  width: '170px',
});

const boxRootStyle: SxProps<Theme> = {
  borderBottom: 1,
  borderColor: 'divider',
  marginBottom: '10px',
};

const accordionRootStyle: SxProps<Theme> = {
  boxShadow: 'none',
};

const accordionDetailsStyle: SxProps<Theme> = {
  padding: '16px',
};

const accordionSummaryStackStyle: SxProps<Theme> = {
  borderBottom: `3px solid ${hpYellowPrimary}`,
};

const accordionSummaryStyle: SxProps<Theme> = {
  width: '160px',
  backgroundColor: hpYellowPrimary,
  borderRadius: '16px 16px 0 0',
  minHeight: '32px',
  height: '32px',
  '&.Mui-expanded': {
    minHeight: '32px',
    height: '32px',
  },
};

const searchFilterTextStyle: SxProps<Theme> = {
  fontSize: '13px',
  fontWeight: 'bold',
};

const clearFiltersButtonStyle: SxProps<Theme> = {
  padding: '7px 15px',
};

const LOCAL_STORAGE_TAB_KEY = 'personnelTab';
const ANY_ROLE = 'Any';

const parentTaskbarTemplate = (props: any) => {
  return (
    <div
      className="e-gantt-parent-taskbar-inner-div e-gantt-parent-taskbar"
      style={{
        height: `${TASKBAR_HEIGHT}px`,
        margin: '0px',
        width: props.ganttProperties.autoWidth + 'px',
        textAlign: 'center',
      }}
    >
      <div
        className="e-gantt-parent-progressbar-inner-div e-row-expand e-gantt-parent-progressbar"
        style={{ width: props.ganttProperties.progressWidth + 'px', height: '100%' }}
      ></div>
      <span
        className="e-task-label"
        style={{
          verticalAlign: 'top',
          color: 'white',
          fontFamily: 'Roboto',
          fontSize: '13px',
          lineHeight: '11px',
          cursor: 'move',
        }}
      >
        {props.taskName}
      </span>
    </div>
  );
};

const labelPeopleSettings = {
  taskLabel: 'TaskName',
};

const taskPeopleFields = {
  id: 'taskID',
  name: 'taskName',
  duration: 'duration',
  title: 'title',
  startDate: 'startDate',
  endDate: 'endDate',
  resourceInfo: 'resources',
  expandState: 'isExpand',
  child: 'subtasks',
  indicators: 'indicators',
};

const personnelTooltipTemplate = (props: any) => {
  const personnel = props.level === 0 ? props.taskName : props.resources;
  const startDate = DateTime.fromJSDate(props.startDate).set({ day: 1 }).toLocaleString();
  const endDate = DateTime.fromJSDate(props.endDate).set({ day: 1 }).toLocaleString();

  const personnelTooltipData = [{ Personnel: personnel }, { 'Start Date ': startDate }, { 'End Date ': endDate }];
  return (
    <div>
      {personnelTooltipData.map((item, index) => {
        return <div key={index}>{`${Object.keys(item)} : ${Object.values(item)}`}</div>;
      })}
    </div>
  );
};

const tooltipSettings = {
  showTooltip: true,
  taskbar: personnelTooltipTemplate,
};

const resourcePeopleFields = {
  id: 'resourceId',
  name: 'resourceName',
  totalLOS: 'totalLOS',
  startDate: 'startDate',
  endDate: 'endDate',
};

const peopleTaskbarTemplate = (props: any) => {
  return (
    props.startDate &&
    props.endDate && (
      <div
        className="e-gantt-child-taskbar-inner-div e-gantt-child-taskbar"
        style={{
          textAlign: 'center',
          height: `${TASKBAR_HEIGHT}px`,
        }}
      >
        <span
          className="e-task-label"
          style={{
            color: 'white',
            fontFamily: 'Roboto',
            fontSize: '13px',
            lineHeight: '11px',
            cursor: 'move',
          }}
        >
          {`${props.project} - ${props.title}`}
        </span>
      </div>
    )
  );
};

export const Personnel: FC = () => {
  const [searchParams, setSearchParams] = useGlobalSearchParams();
  const currentUser = useCurrentUser();
  const { isAuthenticated, isEnterpriseAdmin, isDM, isStockholder, isMarketing, isCraft, isOnlyCraft } = useRoles();
  const selectedDistricts = useRecoilValue(selectedDistrictsState);
  const employeeTypeDropdownValue = useRecoilValue(selectedEmployeeTypeState);
  const selectedEmployeeType = isOnlyCraft ? 'Craft' : employeeTypeDropdownValue;
  const noDistrictSelected = !selectedDistricts?.length;
  const selectedTabSearchParam = searchParams.get('tab');
  const selectedTabLocalStorage = window.localStorage.getItem(LOCAL_STORAGE_TAB_KEY);
  const [selectedTab, setSelectedTab] = useState(
    selectedTabSearchParam
      ? Number(selectedTabSearchParam)
      : selectedTabLocalStorage
      ? Number(selectedTabLocalStorage)
      : 0,
  );
  let tooltipInstance: any = useRef<TooltipComponent | null | undefined>(null);

  const [dataFilterRole, setDataFilterRole] = useDataFilter({
    filter: 'string',
    searchParamKey: 'role',
    localStorageKey: `${PERSONNEL_GANTT_ID}Role`,
  });
  const [dataFilterStartDate, setDataFilterStartDate] = useDataFilter({
    filter: 'date',
    searchParamKey: 'startDate',
    localStorageKey: `${PERSONNEL_GANTT_ID}StartDate`,
  });
  const [dataFilterEndDate, setDataFilterEndDate] = useDataFilter({
    filter: 'date',
    searchParamKey: 'endDate',
    localStorageKey: `${PERSONNEL_GANTT_ID}EndDate`,
  });

  const activeFiltersCount = useMemo(() => {
    let count = 0;
    if (dataFilterRole) count++;
    if (dataFilterStartDate) count++;
    if (dataFilterEndDate) count++;
    return count;
  }, [dataFilterRole, dataFilterStartDate, dataFilterEndDate]);

  const canViewPersonnelDetails = (row: PersonnelGridRowData) => {
    return (
      isEnterpriseAdmin ||
      isDM ||
      isStockholder ||
      isCraft ||
      (isMarketing &&
        (currentUser?.homeDistrict === row.personnel.prDistrict ||
          row.personnel.sharedWithDistricts?.includes(currentUser?.homeDistrict?.toString() ?? '')))
    );
  };

  const personnelTemplate = (props: any) => {
    if (props.level === 0) {
      return (
        <>
          <IconButton
            href={`/personnel/${props.taskID}`}
            target="_blank"
            sx={cellIconButtonStyle}
            disabled={!canViewPersonnelDetails(props)}
          >
            <OpenInNew sx={cellIconStyle} />
          </IconButton>
          {props.taskName}
        </>
      );
    }
  };

  const taskPeopleColumnDirectivePropsLists: React.ComponentProps<typeof ColumnDirective>[] = [
    { field: 'taskID', visible: false, headerText: 'Task ID', isPrimaryKey: true },
    {
      field: 'taskName',
      allowEditing: false,
      width: 225,
      template: personnelTemplate,
      headerTextAlign: 'Center',
    },
    {
      field: 'preferredName',
      allowEditing: false,
      width: 115,
      headerText: 'Preferred Name',
      headerTextAlign: 'Center',
      visible: false,
    },
    {
      field: 'project',
      allowEditing: false,
      headerText: 'Project',
      width: 115,
      headerTextAlign: 'Center',
    },
    {
      field: 'title',
      allowEditing: false,
      width: 75,
      headerText: 'Title',
      headerTextAlign: 'Center',
      filter: { operator: 'contains' },
    },
    {
      field: 'totalLOS',
      allowEditing: false,
      type: 'number',
      width: 115,
      headerText: 'Total LOS',
      headerTextAlign: 'Center',
      sortComparer: lengthOfTimeInYearsSortComparer,
    },
    {
      field: 'startDate',
      allowEditing: false,
      width: 115,
      headerTextAlign: 'Center',
      allowFiltering: false,
    },
    {
      field: 'endDate',
      allowEditing: false,
      width: 115,
      headerTextAlign: 'Center',
      allowFiltering: false,
    },
  ];

  const handleTabChange = (_: any, newReportingTabSelected: number) => {
    setSelectedTab(newReportingTabSelected);
    setSearchParams((previousParams) => {
      // We need to wipe all URL params that are controlled within the tabs.
      ['role', 'startDate', 'endDate'].forEach((key) => previousParams.delete(key));
      previousParams.set('tab', newReportingTabSelected.toString());
      return previousParams;
    });
    window.localStorage.setItem(LOCAL_STORAGE_TAB_KEY, newReportingTabSelected.toString());
  };

  const queryTaskbarInfo: GanttModel['queryTaskbarInfo'] = (args: any) => {
    if (args.data.childRecords.length === 0 && args.data.level === 0) {
      args.taskbarElement.style.display = 'none';
    }
  };

  const resourceToolbarOption = [
    'Search',
    'ZoomIn',
    'ZoomOut',
    'ZoomToFit',
    'CollapseAll',
    { text: 'Go To Today', tooltipText: 'Go To Today', id: 'Today', prefixIcon: 'e-calendar' },
    'PdfExport',
    'Cancel',
  ];

  const personListResultForGanttQueryVariables = {
    order: {
      direction: SortOrderOption.Asc,
      field: 'id',
    },
    search: '',
    isActive: ['Active', 'Awaiting Hire'],
    districts: selectedDistricts,
    isOnlyCraft: selectedEmployeeType === 'Craft',
  };

  const { data, loading } = usePersonListResultForGanttQuery({
    variables: personListResultForGanttQueryVariables,
    fetchPolicy: 'no-cache',
    skip: noDistrictSelected,
  });

  const rawPersonnelGanttData = data?.personListResult.items ?? [];

  const personnelRoleData: PersonnelGridRowData[] = rawPersonnelGanttData.flatMap((personnel) => {
    if (!personnel.roles.length) {
      return [{ personnel }];
    }
    return personnel.roles.map((role) => ({
      personnel,
      role,
    }));
  });

  const personnelGanttData = rawPersonnelGanttData?.map((person) => ({
    resourceId: person.id,
    resourceName: person.name?.lastCommaFirst,
    project: '',
    title: ROLES.find((role) => role.roleName === person.prJobTitle)?.fullDisplayName,
    preferredName: person.name?.preferred,
    isCraft: person.isCraft,
    totalLOS: person.personTenureYear ?? '',
    // For some reason, we have to calculate these directly.
    // Syncfusion will display the correct start/end date in the table,
    // but the task bar will be wrong unless we do this here.
    startDate: person.roles.reduce(
      (accumulator, role) => (!accumulator || accumulator > role.startDate ? role.startDate : accumulator),
      undefined,
    ),
    endDate: person.roles.reduce(
      (accumulator, role) => (!accumulator || accumulator < role.endDate ? role.endDate : accumulator),
      undefined,
    ),
    indicators: person.possiblePromotionDate
      ? [
          {
            date: person.possiblePromotionDate,
            name: ROLES_BY_NAME[person.possiblePromotionTitle ?? '']?.abbreviation,
            tooltip: `possible promotion of ${person.name?.lastCommaFirst} to ${person.possiblePromotionTitle} on ${person.possiblePromotionDate}`,
            iconClass: `e-btn-icon e-notes-info e-icons e-icon-left e-gantt e-notes-info::before ${indicatorIconStyle}`,
          },
        ]
      : [],
  }));

  let index = 0;
  const personnelProjectRolesGanttData = rawPersonnelGanttData?.flatMap((person) => ({
    taskID: index++,
    resourceId: person.id,
    resourceName: person.name?.lastCommaFirst,
    title: person.prJobTitleFullDisplayName,
    isCraft: person.isCraft,
    startDate: person.roles.reduce(
      (accumulator, role) => (!accumulator || accumulator > role.startDate ? role.startDate : accumulator),
      undefined,
    ),
    endDate: person.roles.reduce(
      (accumulator, role) => (!accumulator || accumulator < role.endDate ? role.endDate : accumulator),
      undefined,
    ),
    subtasks:
      person.roles?.map((projectRole) => ({
        taskID: index++,
        taskName: '',
        project: projectRole.project.name,
        title: ROLES.find((role) => role.roleName === projectRole.roleName)?.fullDisplayName,
        startDate: graphqlDateToSyncfusionDate(projectRole.startDate),
        endDate: graphqlDateToSyncfusionDate(projectRole.endDate),
        resources: [{ resourceId: person.id }],
      })) ?? [],
    indicators: person.possiblePromotionDate
      ? [
          {
            date: person.possiblePromotionDate,
            name: ROLES_BY_NAME[person.possiblePromotionTitle ?? '']?.abbreviation,
            tooltip: `possible promotion of ${person.name?.lastCommaFirst} to ${person.possiblePromotionTitle} on ${person.possiblePromotionDate}`,
            iconClass: `e-btn-icon e-notes-info e-icons e-icon-left e-gantt e-notes-info::before ${indicatorIconStyle}`,
          },
        ]
      : [],
  }));

  const rowSpansRange = (
    row: (typeof personnelProjectRolesGanttData)[number],
    startDate: DateTime | null,
    endDate: DateTime | null,
  ) => {
    if (!startDate && !endDate) {
      return true;
    } else if (row.startDate && row.endDate) {
      const roleStart = DateTime.fromISO(row.startDate);
      const roleEnd = DateTime.fromISO(row.endDate);
      if (startDate && endDate) {
        const roleStartsDuringRange = roleStart >= startDate && roleStart <= endDate;
        const roleEndsDuringRange = roleEnd >= startDate && roleEnd <= endDate;
        return roleStartsDuringRange || roleEndsDuringRange || (roleStart <= startDate && roleEnd >= endDate);
      } else if (endDate) {
        return roleStart <= endDate;
      } else if (startDate) {
        return roleEnd >= startDate;
      } else {
        throw new Error('Should be unreachable.');
      }
    } else {
      return false;
    }
  };

  const resourceSpansRange = (
    resource: (typeof personnelGanttData)[number],
    startDate: DateTime | null,
    endDate: DateTime | null,
  ) => {
    if (!startDate && !endDate) {
      return true;
    } else if (resource.startDate && resource.endDate) {
      const roleStart = DateTime.fromISO(resource.startDate);
      const roleEnd = DateTime.fromISO(resource.endDate);
      if (startDate && endDate) {
        const roleStartsDuringRange = roleStart >= startDate && roleStart <= endDate;
        const roleEndsDuringRange = roleEnd >= startDate && roleEnd <= endDate;
        return roleStartsDuringRange || roleEndsDuringRange || (roleStart <= startDate && roleEnd >= endDate);
      } else if (endDate) {
        return roleStart <= endDate;
      } else if (startDate) {
        return roleEnd >= startDate;
      } else {
        throw new Error('Should be unreachable.');
      }
    } else {
      return false;
    }
  };

  const actionBegin: GanttModel['actionBegin'] = (args: any) => {
    if (args.requestType === 'filterbeforeopen' && (args.columnName === 'startDate' || args.columnName === 'endDate')) {
      args.columnType = 'datetime';
      args.filterModel.col.type = 'datetime';
    }
  };

  const onClearAllDataFilters = () => {
    setDataFilterRole(null);
    setDataFilterStartDate(null);
    setDataFilterEndDate(null);
  };

  const getEmployeeType = (row: any) => {
    const isEmployeeCraftOrSalary = row.isCraft ? 'Craft' : 'Salary';
    return isEmployeeCraftOrSalary;
  };

  const filteredDataSource = personnelProjectRolesGanttData.filter(
    (row) =>
      row.title &&
      (dataFilterRole === null || dataFilterRole === row.title) &&
      rowSpansRange(row, dataFilterStartDate, dataFilterEndDate) &&
      selectedEmployeeType === getEmployeeType(row),
  );

  const filteredResources = personnelGanttData.filter(
    (row) =>
      row.title &&
      (dataFilterRole === null || dataFilterRole === row.title) &&
      resourceSpansRange(row, dataFilterStartDate, dataFilterEndDate) &&
      selectedEmployeeType === getEmployeeType(row),
  );

  const beforeRender = (args: any) => {
    const elementWithTooltipProp = args.target.closest('td').querySelector('[data-tooltip]');
    if (elementWithTooltipProp) {
      tooltipInstance.content = elementWithTooltipProp.getAttribute('data-tooltip');
    } else {
      args.cancel = true;
    }
  };

  return (
    <Stack>
      <Box>
        <>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              {noDistrictSelected && (
                <Typography>Please select at least one region from the filter at the top of the page.</Typography>
              )}
              {!noDistrictSelected && (loading || !isAuthenticated) && <LoadingSpinner />}
              {!noDistrictSelected && !loading && isAuthenticated && (
                <>
                  <Card>
                    <Fragment>
                      <Box sx={boxRootStyle}>
                        <Tabs value={selectedTab} onChange={handleTabChange}>
                          <Tab label="All Personnel" />
                          <Tab label="Free Personnel" />
                          <Tab label="Gantt" />
                        </Tabs>
                      </Box>
                      <TabPanel value={selectedTab} index={0}>
                        {(loading || !isAuthenticated) && <LoadingSpinner />}
                        {!loading && isAuthenticated && (
                          <PersonnelGrid gridId={PERSONNEL_GRID_ID} dataSource={personnelRoleData} />
                        )}
                      </TabPanel>
                      <TabPanel value={selectedTab} index={1}>
                        {(loading || !isAuthenticated) && <LoadingSpinner />}
                        {!loading && isAuthenticated && (
                          <PersonnelAvailabilityGrid
                            gridId={PERSONNEL_UNASSIGNED_GRID_ID}
                            personnelList={rawPersonnelGanttData}
                          />
                        )}
                      </TabPanel>
                      <TabPanel value={selectedTab} index={2}>
                        <Accordion sx={accordionRootStyle}>
                          <Stack direction="column" sx={accordionSummaryStackStyle}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={accordionSummaryStyle}>
                              <Typography sx={searchFilterTextStyle}>Search Filter ({activeFiltersCount})</Typography>
                            </AccordionSummary>
                          </Stack>
                          <AccordionDetails sx={accordionDetailsStyle}>
                            <Grid container>
                              <Stack direction="row" spacing={7}>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                  <Typography>Show</Typography>
                                  <Autocomplete<string>
                                    id="RoleFilter"
                                    size="small"
                                    options={[
                                      ANY_ROLE,
                                      ...ROLES_FULL_DISPLAY_NAME_BY_JOB_TYPE(selectedEmployeeType === 'Craft'),
                                    ]}
                                    value={dataFilterRole ?? ANY_ROLE}
                                    onChange={(_, value) => setDataFilterRole(value === ANY_ROLE ? null : value)}
                                    renderInput={(params) => <TextField {...params} />}
                                    sx={roleSelectStyle}
                                    PopperComponent={styled(Popper)(({ theme }) => ({
                                      [`& .${autocompleteClasses.paper}`]: {
                                        width: '400px',
                                        // Shadow 8 is the same one that the StyledDatePicker ends up using.
                                        boxShadow: theme.shadows[8],
                                      },
                                    }))}
                                  />
                                  <Typography>personnel and roles between:</Typography>
                                  <StyledDatePicker
                                    value={dataFilterStartDate ? convertUTCDateToLocalDate(dataFilterStartDate) : null}
                                    onChange={setDataFilterStartDate}
                                    maxDate={dataFilterEndDate || undefined}
                                    sx={dateSelectStyle}
                                    slotProps={{ textField: { size: 'small' } }}
                                  />
                                  <Typography>and:</Typography>
                                  <StyledDatePicker
                                    value={dataFilterEndDate ? convertUTCDateToLocalDate(dataFilterEndDate) : null}
                                    onChange={setDataFilterEndDate}
                                    minDate={dataFilterStartDate || undefined}
                                    sx={dateSelectStyle}
                                    slotProps={{ textField: { size: 'small' } }}
                                  />
                                  <Button
                                    onClick={onClearAllDataFilters}
                                    variant="outlined"
                                    sx={clearFiltersButtonStyle}
                                  >
                                    Clear Filters
                                  </Button>
                                </Stack>
                              </Stack>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                        <TooltipComponent
                          ref={(tooltip: TooltipComponent | null | undefined) => (tooltipInstance = tooltip)}
                          target=".e-rowcell"
                          beforeRender={beforeRender}
                        >
                          <GanttChart
                            ganttComponentProps={{
                              id: PERSONNEL_GANTT_ID,
                              viewType: 'ResourceView',
                              dataSource: filteredDataSource,
                              taskFields: taskPeopleFields,
                              labelSettings: labelPeopleSettings,
                              resourceFields: resourcePeopleFields,
                              resources: filteredResources,
                              parentTaskbarTemplate: parentTaskbarTemplate,
                              taskbarTemplate: peopleTaskbarTemplate,
                              showOverAllocation: true,
                              enableMultiTaskbar: false,
                              enableTimelineVirtualization: true,
                              enableVirtualization: true,
                              allowResizing: true,
                              allowRowDragAndDrop: false,
                              editSettings: {
                                allowAdding: false,
                                allowEditing: false,
                                // Need to allow deleting in order for external filters to work
                                allowDeleting: true,
                                allowTaskbarEditing: false,
                                mode: 'Dialog',
                              },
                              actionBegin,
                              queryTaskbarInfo,
                              tooltipSettings,
                              // As opposed to Manual mode, this mode will tie the start/end date of the resource to its child tasks
                              taskMode: 'ResourceView',
                            }}
                            columnDirectivePropsList={taskPeopleColumnDirectivePropsLists}
                            toolbar={resourceToolbarOption}
                          />
                        </TooltipComponent>
                      </TabPanel>
                    </Fragment>
                  </Card>
                </>
              )}
            </Grid>
          </Grid>
        </>
      </Box>
    </Stack>
  );
};

export default Personnel;
